import React from 'react'
import { Navigate, Outlet, useLocation } from 'react-router-dom'
import { 
	AUTH_PREFIX_PATH, 
	UNAUTHENTICATED_ENTRY, 
	REDIRECT_URL_KEY 
} from 'configs/AppConfig'
import useAuth from "@hook/useAuth";

const ProtectedRoute = () => {

	const {isLoggedIn} = useAuth()
	const location = useLocation()

	if (!isLoggedIn) {
		return <Navigate to={`${AUTH_PREFIX_PATH}${UNAUTHENTICATED_ENTRY}?${REDIRECT_URL_KEY}=${location.pathname}`} replace />;
	}

	return <Outlet />
}

export default ProtectedRoute