import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter  } from 'react-router-dom'
import { ThemeSwitcherProvider } from 'react-css-theme-switcher';
import {PersistGate} from "redux-persist/integration/react";
import {store, persist} from '@store';
import history from './history'
import Layouts from './layouts'

// Import Shared Components
import Loading from "@sharedComponent/Loading";

// Import Hooks
import useTheme from "@hook/useTheme";

// Import Libraries
import { useJsApiLoader } from '@react-google-maps/api'

// Import Styles
import './assets/css/font-awesome.min.css'
import 'bootstrap/scss/bootstrap-grid.scss'
import 'suneditor/dist/css/suneditor.min.css'

const themes = {
	dark: `${process.env.PUBLIC_URL}/css/dark-theme.css`,
	light: `${process.env.PUBLIC_URL}/css/light-theme.css`,
};

const MainApp = () => {
	
	const {currentTheme} = useTheme()
	const { isLoaded } = useJsApiLoader({
		id: 'google-map-script',
		googleMapsApiKey: "AIzaSyAmxKdh7EzV_TmxD6qIS4xQNL1uNVToNB8",
		libraries: ["places"]
	})

	return isLoaded ? (
		<ThemeSwitcherProvider
			themeMap={themes}
			defaultTheme={currentTheme}
			insertionPoint="styles-insertion-point"
		>
			<Layouts />
		</ThemeSwitcherProvider>
	)
	:
	(
		<Loading/>
	)
}

function App() {
	return (
		<div className="App">
			<Provider store={store}>
				<PersistGate persistor={persist}>
					<BrowserRouter history={history}>
						<MainApp/>
					</BrowserRouter>
				</PersistGate>
			</Provider>
		</div>
	);
}

export default React.memo(App);
