import React, {useEffect} from 'react'
import { ConfigProvider } from 'antd'
import Routes from 'routes'
import useBodyClass from 'utils/hooks/useBodyClass'
import useTheme from "@hook/useTheme"

import {
	QueryClient,
	QueryClientProvider,
} from '@tanstack/react-query'

// import {io} from 'socket.io-client'
// import SocketContext from "@context/SocketContext";

export const Views = () => {
	const {direction} = useTheme()
	const queryClient = new QueryClient()

	useBodyClass(`dir-${direction}`);

	useEffect(() => {
        if (window === undefined) {
            return
        }
        const root = window.document.documentElement
        root.setAttribute('dir', direction)
    }, [direction])

	return (
		<ConfigProvider direction={direction} >
			<QueryClientProvider client={queryClient}>
				<Routes />
			</QueryClientProvider>
		</ConfigProvider>
	)
}

export default React.memo(Views);