import { createAction, createReducer } from 'redux-act'

const initAuth = {
	tokens: {},
	user: {},
	role: {}
}

export const setTokens = createAction('SET_TOKENS', arg => arg)
export const unsetTokens = createAction('UNSET_TOKENS')
export const setUser = createAction('SET_USER', arg => arg)
export const unsetUser = createAction('UNSET_USER')
export const setRole = createAction('SET_ROLE', arg => arg)
export const unsetRole = createAction('UNSET_ROLE')
export const setUserAvatar = createAction('SET_USER_AVATAR', arg => arg)
export const unsetUserAvatar = createAction('UNSET_USER_AVATAR')

const auth = createReducer({
	[setTokens]: (state, user) => ({
		...state,
		tokens: user
	}),
	[unsetTokens]: (state) => ({
		...state,
		tokens: {}
	}),
	[setUser]: (state, user) => ({
		...state,
		user: user
	}),
	[unsetUser]: (state) => ({
		...state,
		user: {}
	}),
	[setRole]: (state, role) => ({
		...state,
		role: role
	}),
	[unsetRole]: (state) => ({
		...state,
		role: {}
	}),
	[setUserAvatar]: (state, avatar) => ({
		...state,
		user: {
			...state.user,
			avatar: avatar
		}
	}),
	[unsetUserAvatar]: (state) => ({
		...state,
		user: {
			...state.user,
			avatar: null
		}
	})
}, initAuth);

export default auth
