import {useDispatch, useSelector} from "react-redux"
import {
    openSideNav as osn,
    closeSideNav as csn,
    toggleSideNav as tsn,
    changeSideNavTheme as csnt,
    changeNavType as cnt,
    openMobileNav as omn,
    closeMobileNav as cmn,
    toggleMobileNav as tmn,
    switchTheme as st,
    changeDirection as cd,
    changeTopNavColor as ctc,
    changeHeaderColor as chc,
    blankLayout as bl
} from "@reducer/Theme"
import utils from 'utils/index'

const useTheme = () => {

    const dispatch = useDispatch()

    const {
        navCollapsed,
        sideNavTheme,
        locale,
        navType,
        topNavColor,
        headerNavColor,
        mobileNav,
        currentTheme,
        direction,
        blankLayout
    } = useSelector(state => state.theme)

    const openSideNav = () => {
        dispatch(osn())
    }

    const closeSideNav = () => {
        dispatch(csn())
    }

    const toggleSideNav = () => {
        dispatch(tsn())
    }

    const changeSideNavTheme = value => {
        dispatch(csnt(value))
    }

    const changeNavType = value => {
        dispatch(cnt(value))
    }

    const openMobileNav = () => {
        dispatch(omn())
    }

    const closeMobileNav = () => {
        dispatch(cmn())
    }

    const toggleMobileNav = () => {
        dispatch(tmn())
    }

    const switchTheme = value => {
        dispatch(st(value))
    }

    const changeDirection = value => {
        dispatch(cd(value))
    }

    const changeTopNavColor = value => {
        if (value) {
            const {rgb} = value
            const rgba = `rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, ${rgb.a})`
            const hex = utils.rgbaToHex(rgba)
            dispatch(ctc(hex))
        } else {
            dispatch(ctc('#3e82f7'))
        }
    }

    const changeHeaderNavColor = value => {
        if (value) {
            const {rgb} = value
            const rgba = `rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, ${rgb.a})`
            const hex = utils.rgbaToHex(rgba)
            dispatch(chc(hex))
        } else {
            dispatch(chc(''))
        }
    }

    const changeBlankLayout = value => {
        dispatch(bl(value))
    }

    return {
        navCollapsed,
        sideNavTheme,
        locale,
        navType,
        topNavColor,
        headerNavColor,
        mobileNav,
        currentTheme,
        direction,
        blankLayout,

        openSideNav,
        closeSideNav,
        toggleSideNav,
        changeSideNavTheme,
        changeTopNavColor,
        changeHeaderNavColor,
        changeNavType,
        openMobileNav,
        closeMobileNav,
        toggleMobileNav,
        switchTheme,
        changeDirection,
        changeBlankLayout
    }

}

export default useTheme