import React  from 'react'
import { Navigate, Outlet } from 'react-router-dom'
import { AUTHENTICATED_ENTRY } from 'configs/AppConfig'
import useAuth from "@hook/useAuth";

const PublicRoute = () => {
	const {isLoggedIn} = useAuth()
	return isLoggedIn ? <Navigate to={AUTHENTICATED_ENTRY} /> : <Outlet/>
}

export default PublicRoute