import React from 'react'
import { AUTH_PREFIX_PATH, APP_PREFIX_PATH } from 'configs/AppConfig'

export const publicRoutes = [
    {
        key: 'login',
        path: `${AUTH_PREFIX_PATH}/login`,
        component: React.lazy(() => import('views/auth-views/authentication/login')),
    },
    {
        key: 'register',
        path: `${AUTH_PREFIX_PATH}/register-1`,
        component: React.lazy(() => import('views/auth-views/authentication/register')),
    },
    {
        key: 'forgot-password',
        path: `${AUTH_PREFIX_PATH}/forgot-password`,
        component: React.lazy(() => import('views/auth-views/authentication/forgot-password')),
    }
]

export const protectedRoutes = [
    {
        key: 'dashboard.default',
        path: `${APP_PREFIX_PATH}/dashboard/default`,
        component: React.lazy(() => import('views/app-views/dashboards/default'))
    },
    {
        key: 'dashboard.analytic',
        path: `${APP_PREFIX_PATH}/dashboard/analytic`,
        component: React.lazy(() => import('views/app-views/dashboards/analytic'))
    },
    {
        key: 'dashboard.sales',
        path: `${APP_PREFIX_PATH}/dashboard/sales`,
        component: React.lazy(() => import('views/app-views/dashboards/sales'))
    },
    {
        key: 'user.role',
        path: `${APP_PREFIX_PATH}/user/role`,
        component: React.lazy(() => import('views/app-views/user/role'))
    },
    {
        key: 'user.admin',
        path: `${APP_PREFIX_PATH}/user/admin`,
        component: React.lazy(() => import('views/app-views/user/admin'))
    },
    {
        key: 'user.client',
        path: `${APP_PREFIX_PATH}/user/client`,
        component: React.lazy(() => import('views/app-views/user/client'))
    },
    {
        key: 'inventory.product',
        path: `${APP_PREFIX_PATH}/inventory/product`,
        component: React.lazy(() => import('views/app-views/inventory/product'))
    },
    {
        key: 'inventory.purchase',
        path: `${APP_PREFIX_PATH}/inventory/purchase`,
        component: React.lazy(() => import('views/app-views/inventory/purchase'))
    },
    {
        key: 'inventory.sell',
        path: `${APP_PREFIX_PATH}/inventory/sell`,
        component: React.lazy(() => import('views/app-views/inventory/sell'))
    },
    {
        key: 'user.supplier',
        path: `${APP_PREFIX_PATH}/user/supplier`,
        component: React.lazy(() => import('views/app-views/user/supplier'))
    },
    {
        key: 'accounts.account',
        path: `${APP_PREFIX_PATH}/accounts/account`,
        component: React.lazy(() => import('views/app-views/accounts/account'))
    },
    {
        key: 'accounts.transfer',
        path: `${APP_PREFIX_PATH}/accounts/transfer`,
        component: React.lazy(() => import('views/app-views/accounts/transfer'))
    },
    {
        key: 'accounts.expense',
        path: `${APP_PREFIX_PATH}/accounts/expense`,
        component: React.lazy(() => import('views/app-views/accounts/expense'))
    },
    {
        key: 'settings',
        path: `${APP_PREFIX_PATH}/settings/*`,
        component: React.lazy(() => import('views/app-views/settings'))
    }
]