import {THEME_CONFIG} from 'configs/AppConfig'
import { createAction, createReducer } from 'redux-act';

const initTheme = {
	...THEME_CONFIG
};

export const openSideNav = createAction('OPEN_SIDE_NAV');
export const closeSideNav = createAction('CLOSE_SIDE_NAV');
export const toggleSideNav = createAction('TOGGLE_SIDE_NAV');
export const changeSideNavTheme = createAction('CHANGE_SIDE_NAV_THEME', arg => arg);
export const changeLocale = createAction('CHANGE_LOCALE', arg => arg);
export const changeNavType = createAction('CHANGE_NAV_TYPE', arg => arg);
export const changeTopNavColor = createAction('CHANGE_TOP_NAV_COLOR', arg => arg);
export const changeHeaderColor = createAction('CHANGE_HEADER_COLOR', arg => arg);
export const openMobileNav = createAction('OPEN_MOBILE_NAV');
export const closeMobileNav = createAction('CLOSE_MOBILE_NAV');
export const toggleMobileNav = createAction('TOGGLE_MOBILE_NAV');
export const switchTheme = createAction('SWITCH_THEME', arg => arg);
export const changeDirection = createAction('CHANGE_DIRECTION', arg => arg);
export const blankLayout = createAction('BLANK_LAYOUT', arg => arg);

const theme = createReducer({
	[openSideNav]: (state) => ({
		...state,
		navCollapsed: false
	}),
	[closeSideNav]: (state) => ({
		...state,
		navCollapsed: true
	}),
	[toggleSideNav]: (state) => ({
		...state,
		navCollapsed: !state.navCollapsed
	}),
	[changeSideNavTheme]: (state, payload) => ({
		...state,
		sideNavTheme: payload
	}),
	[changeLocale]: (state, payload) => ({
		...state,
		locale: payload
	}),
	[changeNavType]: (state, payload) => ({
		...state,
		navType: payload
	}),
	[changeTopNavColor]: (state, payload) => ({
		...state,
		topNavColor: payload
	}),
	[changeHeaderColor]: (state, payload) => ({
		...state,
		headerNavColor: payload
	}),
	[openMobileNav]: (state) => ({
		...state,
		mobileNav: true
	}),
	[closeMobileNav]: (state) => ({
		...state,
		mobileNav: false
	}),
	[toggleMobileNav]: (state) => ({
		...state,
		mobileNav: !state.mobileNav
	}),
	[switchTheme]: (state, payload) => ({
		...state,
		currentTheme: payload,
		sideNavTheme: 'light',
		headerNavColor: ''
	}),
	[changeDirection]: (state, payload) => ({
		...state,
		direction: payload
	}),
	[blankLayout]: (state, payload) => ({
		...state,
		blankLayout: payload
	})
}, initTheme);

export default theme
