import {useDispatch, useSelector} from "react-redux"
import {decodeToken} from "react-jwt"
import _ from "lodash"
import {setTokens as st, unsetTokens as ust, setUser as su, unsetUser as usu, setRole as sr, unsetRole as usr, setUserAvatar as sua, unsetUserAvatar as usua} from "@reducer/Auth"

const useAuth = () => {
	const {user, tokens, role} = useSelector(state => state.auth)
	const dispatch = useDispatch()
	
	const setTokens = (tokens) => {
		dispatch(st(tokens))
	}
	
	const unsetTokens = () => {
		dispatch(ust())
	}
	
	const setUser = (user) => {
		dispatch(su(user))
	}
	
	const unsetUser = () => {
		dispatch(usu())
	}

	const setRole = (role) => {
		dispatch(sr(role))
	}

	const unsetRole = () => {
		dispatch(usr())
	}

	const setUserAvatar = (avatar) => {
		dispatch(sua(avatar))
	}

	const unsetUserAvatar = () => {
		dispatch(usua())
	}
	
	const getAccessToken = () => {
		if (tokens && tokens.hasOwnProperty('accessToken')) {
			return tokens.accessToken
		}
		return null
	}
	
	const getAccessTokenDecoded = () => {
		if (getAccessToken()) {
			return decodeToken(getAccessToken())
		}
		return null
	}
	
	const getRefreshToken = () => {
		if (tokens && tokens.hasOwnProperty('refreshToken')) {
			return tokens.refreshToken
		}
		return false
	}
	
	const getRefreshTokenDecoded = () => {
		if (getRefreshToken()) {
			return decodeToken(getRefreshToken())
		}
		return null
	}
	
	const getUser = () => {
		return user
	}
	
	const isTokenExpired = (type='access') => {
		let token = ''
		
		if (type === 'access') {
			token = getAccessTokenDecoded()
		} else if (type === 'refresh') {
			token = getRefreshTokenDecoded()
		}
		
		if (token) {
			const dt = new Date(token.exp+'UTC')
			const rightNow = new Date()
			return dt > rightNow
		}
		return false
	}
	
	const checkIsLoggedIn = () => {
		if (tokens.hasOwnProperty('accessToken') && tokens.hasOwnProperty('refreshToken')) {
			if(!isTokenExpired('refresh')) {
				return checkIsUserExists()
			}
			return false
		}
		return false
	}
	
	const checkIsUserExists = () => {
		const usr = getUser()
		return usr.hasOwnProperty('_id') && usr.hasOwnProperty('name') && usr.hasOwnProperty('username') && !_.isEmpty(role)
	}
	
	const isLoggedIn = checkIsLoggedIn()
	const isUserExists = checkIsUserExists()
	const accessToken = getAccessToken()
	const accessTokenDecoded = getAccessTokenDecoded()
	const refreshToken = getRefreshToken()
	const refreshTokenDecoded = getRefreshTokenDecoded()
	
	return {
		setTokens,
		unsetTokens,
		setUser,
		unsetUser,
		setRole,
		unsetRole,
		setUserAvatar,
		unsetUserAvatar,
		getUser,
		getAccessToken,
		getAccessTokenDecoded,
		getRefreshToken,
		getRefreshTokenDecoded,
		user,
		isLoggedIn,
		isUserExists,
		accessToken,
		accessTokenDecoded,
		refreshToken,
		refreshTokenDecoded
	}
}

export default useAuth
