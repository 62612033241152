import {combineReducers} from 'redux';
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import Auth from '@reducer/Auth';
import Theme from '@reducer/Theme';
import Config from '@reducer/Config';

const persistConfig = (key, blacklist = []) =>  {
	return {
		key,
		blacklist,
		storage
	}
}

const reducers = combineReducers({
	theme: persistReducer(persistConfig('theme'), Theme),
	auth: persistReducer(persistConfig('auth'), Auth),
	config: persistReducer(persistConfig('config'), Config)
});

export default reducers;
