import React, { lazy, Suspense, memo } from 'react'
import Loading from 'components/shared-components/Loading';
import Views from 'views';
import useAuth from "@hook/useAuth";
import useTheme from "@hook/useTheme";

const AppLayout = lazy(() => import('./AppLayout'));
const AuthLayout = lazy(() => import('./AuthLayout'));

const Layouts = () => {
	const {isLoggedIn} = useAuth()
	const {blankLayout} = useTheme()

	const Layout = isLoggedIn && !blankLayout ? AppLayout : AuthLayout

	return (
		<Suspense fallback={<Loading cover="content"/>}>
			<Layout>
				<Views />
			</Layout>
		</Suspense>
	)
}

export default memo(Layouts)