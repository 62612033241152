import { createAction, createReducer } from 'redux-act'

const initAuth = {
    configs: [],
    fetched: false,
    fetchedAt: ''
}

export const setConfigs = createAction('SET_CONFIGS', arg => arg)

const config = createReducer({
    [setConfigs]: (state, configs) => ({
        ...state,
        configs: configs,
        fetched: true,
        fetchedAt: new Date().toString()
    })
}, initAuth);

export default config
