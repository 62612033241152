import React, { useEffect } from 'react'
import useTheme from "@hook/useTheme"

const AppRoute = ({ component: Component, routeKey, ...props }) => {
	const {blankLayout, changeBlankLayout} = useTheme()

	useEffect(() => {
		const isBlank = !!blankLayout
		changeBlankLayout(isBlank)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [blankLayout])
	
	return (
		<Component {...props} />
	)
}

export default React.memo(AppRoute)