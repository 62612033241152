import {applyMiddleware, compose, createStore} from "redux";
import {persistStore} from 'redux-persist'
import reducers from "@reducer/index";
import thunkMiddleware from 'redux-thunk'

const middlewares = [thunkMiddleware];

function configureStore(preloadedState) {

	const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
	const store = createStore(reducers, preloadedState, composeEnhancers(
		applyMiddleware(...middlewares)
	));

	if (module.hot) {
		module.hot.accept("../reducers/index", () => {
			const nextRootReducer = require("../reducers/index");
			store.replaceReducer(nextRootReducer);
		});
	}

	return store;
}

export const store = configureStore();
export const persist = persistStore(store)

